    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        overflow: hidden;
        position: relative;
        max-height: 100%;
        cursor: url("images/cursor.cur"), pointer;
    }

    html {
        height: 100%;
        width: 100%;

    }

    body {
        min-width: 100%;
        min-height: 100%;
    }

    #neo-background {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    * {
        margin: 0;
        padding: 0;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    .header {
        position: fixed;
        top: 0;
        left: 0;
        height: 20px;
        background: transparent;
        z-index: 99999999;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 1em;
        margin-right: 1em;
    }