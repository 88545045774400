.hideout-header {
  border-radius: 1em;
}
.hideout-header:hover {
  border: none;
  box-shadow: none !important;
  animation: none;
}

.hideout-header:before {
  animation: none !important;
}
.hideout-header:after {
  animation: none !important;
}

.hideout-header-divider {
  display: flex;
  justify-content: space-between;
  grid-template-columns: 1fr 3fr;
  white-space: nowrap;
  column-gap: 10px;
}
.hideout-header-info {
  display: flex;
  font-size: 1.2em;
  font-weight: bolder;
  z-index: 99;
}
.batmfa-font {
  font-family: "batmfa";
}
.hideout-header-value-description {
  color: #053a5e;
  margin: 3px;
}
.hideout-header-value {
  color: white;
  margin: 3px;
}
.hideout-header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.hideout-header-description-container-first {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  column-gap: 100px;
}
.hideout-header-description-container-second {
  width: fit-content;
  clip-path: polygon(0 0, 100% 1%, 88% 100%, 0% 100%);
  margin-top: -9px;
  padding-bottom: 10px;
}
.display-flex {
  display: flex;
}
.space-betweeen {
  justify-content: space-between;
}
.padding-right {
  padding-right: 30px;
}
.padding-right-end {
  padding-right: 15px;
}
.back-button {
  background: transparent;
  border: none;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.background-light-blue {
  background-color: #8ebad3;
}
.neo-ninja {
  background-color: #075d8d;
  margin-top: 10px;
  margin-right: 10px;
  clip-path: polygon(0 0, 100% 1%, 90% 100%, 0% 100%);
  padding-right: 10px;
  border-radius: 6px;
}
#chart {
  width: 90%;
  margin-top: -1%;
}
.justify-content-center {
  justify-content: center;
}
.apexcharts-toolbar {
  display: none !important;
}
.timezone-container > ul {
  background-color: #043a58;
  width: 250px;
  justify-content: space-around;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 0px;
}
.timezone-container > div {
  margin-bottom: 0px;
  color: #BEDCF0;
  font-family: 'BatmanForeverAlternate';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
}
.timezone-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
}
.dashboard-context{
  padding: 25px 25px 5px;
}
.ant-pagination {
  display: none;
}
.table-class {
  width: 88%;
  height: 30%;
}
.ant-table-tbody > tr > td {
  padding: 10px 14px;
}
.ant-table-thead > tr > th {
  color: #05496e;
  font-family: "Poppins" !important;
  font-weight: bold;
}
.ant-table {
  background: none !important;
}
.ant-table-column-sorter-up.active {
  color: #0beee3 !important;
}
.ant-table-column-sorter-down.active {
  color: #0beee3 !important;
}
.anticon {
  color: #043a58 !important;
}
.ant-table-thead th.ant-table-column-sort {
  background: none !important;
}
td.ant-table-column-sort {
  background: none !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.ant-table-row .ant-table-cell:first-child {
  padding: 0px;
}
.table-name {
  background: linear-gradient(181.58deg, #043a58 1.34%, #000000 156.07%);
  border-radius: 6px;
  padding: 8px 1px;
  text-align: center;
  color: white;
}

.button-container {
  display: flex;
  align-items: flex-start;
}

.hideout-mobile-divider {
  display: none;
}

.dashboard-map-area {
  background-image: url("../../images/background_picture.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: initial;
}

.table-bottom {
  margin-bottom: 83px;
}
.hideout-header-image > img {
  width: 65px;
  height: 100%;
}


@media screen and (max-width: 1500px) {
  .button-container > button {
    width: 140px;
    height: 50px;
  }

  .batmfa-font {
    font-size: 14px;
  }
  .back-button {
    padding: 0px;
  }
  .hideout-mobile-divider {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .hideout-header-description-container-first{
    column-gap: 60px;
  }
}

@media screen and (max-width: 1020px) {
  .hideout-header-description-container-first{
    column-gap: 0px;
  }
}

@media screen and (max-width: 1300px) {
  .button-container > button {
    width: 100px;
    height: 34px;
  }
  .batmfa-font {
    font-size: 10px;
  }
  .back-button {
    padding: 0px;
  }
  .hideout-header-image > img {
    width: 55px;
    height: 100%;

  }
  .back-button > img {
    width: 40px;
    height: 40px;
  }
  .hideout-mobile-divider {
    display: none;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1181px)  {
  .table-bottom {
    margin-bottom: 75px;
  }
}

@media only screen and (max-width: 1650px) and (min-width: 1300px)  {
  .table-bottom {
    margin-bottom: 55px;
  }
}
@media only screen and (max-width: 1900px) and (min-width: 1650px)  {
  .table-bottom {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 1180px) {
  .table-bottom  {
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 1650px) {
  .timezone-container > div {
    font-size: 20px;
  }
}

@media screen and (max-width: 1350px) {
  .timezone-container > div {
    font-size: 15px;
  }
}

@media screen and (max-width: 900px) {
  .video-hideout-main {
    left: -100% !important;
  }
  .hideout-desktop {
    width: 27% !important;
  }
  .hideout-header {
    left: 34% !important;
    width: 32% !important;
    top: 3% !important;
  }
  .hideout-header-divider {
    display: none;
  }
  .hideout-mobile-divider {
    display: flex;
  }
  .hideout-header-image > img {
    width: 100px;
    height: 100%;

  }
  .batmfa-font {
    font-size: 16px;
  }
  .neo-ninja {
    margin-top: 10px;
  }
  .hideout-header-description-container-second {
    padding-right: 9px;
    border-bottom-left-radius: 8px;
  }
  .button-container > button {
    width: 150px;
  }
  .desktop-class {
    width: 28.5% !important;
    left: 36% !important;
  }
}

@media screen and (max-width: 510px) {
  .hideout-header-image > img {
    width: 80px;
    height: 80px;
  }
  .batmfa-font {
    font-size: 9px;
  }
  .neo-ninja {
    margin-top: 10px;
  }
  .button-container > button {
    width: 100px;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 2px;
  }
  .ant-table-thead > tr > th {
    padding: 16px 2px;
  }
  .ant-table {
    font-size: 10px;
  }
}
.ant-table-cell {
  padding: 2px !important;
}
