@import "~antd/dist/antd.css";

.App {
  overflow-x: hidden;
  height: 100%;
}

@media (max-width: 1700px) {
  .App {
    height: 100%;
  }
}

@font-face {
  font-family: "batmfa";
  src: url("./fonts/BatmanForeverAlternate/batmfa.ttf") format("truetype");
}


.disconnect-wallet-button {
  background-image: url("./images/Disconnect_Wallet.png") !important;
  background-position: center !important;
  background-size: cover !important;
  background-color: transparent !important;
  transition: 0.2s all;
  box-shadow: none !important;
  justify-content: flex-end;
}


.disconnect-wallet-button>span {
  display: none;
}

.connect-wallet-button {
  background-image: url("./images/Connect_Wallet.png") !important;
  background-position: center !important;
  background-size: cover !important;
  background-color: transparent !important;
  transition: 0.2s all;
  box-shadow: none !important;
}

.connect-wallet-button:hover {
  background-image: url("./images/Connect_Wallet_Hover.png") !important;
}

.connect-wallet-button:focus {
  background-image: url("./images/Connect_Wallet_clicked.png") !important;
}

.anticon {
  color: #851cef !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #273043;
}
.ant-table-tbody > tr > td {
  border-bottom: 8px solid #0f091a;
}
.ant-table-container table > thead > tr:first-child th {
  border-bottom: none;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #434a59 !important;
}
.ant-layout {
  background: #161520;
}
.ant-table {
  background: linear-gradient(
    241.25deg,
    #222635 4.4%,
    rgba(33, 35, 44, 0.71) 61.77%,
    rgba(26, 32, 53, 0.49) 119.94%
  );
  min-width: 100%;
  width: fit-content;
}
.ant-table-thead > tr > th {
  background: transparent;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: transparent;
}
.ant-table-tbody > tr.ant-table-placeholder > td {
  background: transparent;
}
.ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

.ant-modal-content {
  background-color: #212734;
}
.ant-select-selector {
  mix-blend-mode: normal;
  /* accent_blue */

  border: 1px solid #851cef !important;
  border-radius: 6px !important;
}

.ant-modal-content {
  background: #2e2c3c;
  border: 2px solid #6f2da8;
  border-radius: 10px;
}
.ant-modal-header {
  background: #851cef;
  border-radius: 10px 10px 0px 0px;
}
.ant-modal-wrap {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
}
.ant-modal-title {
  text-align: center;
  font-weight: 700;
}

.ant-menu-horizontal > .ant-menu-item {
  font-size: 22px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  color: #898989;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff;
}

.ant-slider-track,
.ant-slider ant-slider-track:hover {
  background-color: #fff !important;
  opacity: 0.75;
}
.ant-slider-track,
.ant-slider ant-slider-track {
  background-color: #fff;
  opacity: 0.75;
}
.ant-slider-dot-active,
.ant-slider-handle,
.ant-slider-handle-click-focused,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border: 2px solid #fff !important;
}
.ant-slider-handle {
  background: #851cef;
  box-shadow: 0px 0px 14px 2px #851cef;
}
.ant-slider-track {
  border: 1px solid #ffffff;
}
.ant-switch-checked {
  background-color: #851cef;
}
.eZVpPo,
.gbgfMs {
  padding-bottom: 0px;
}

.ant-select-selection-search-input {
  vertical-align: -webkit-baseline-middle;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding-top: 5px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  font-weight: 700;
}
.ant-tabs-tab:hover {
  color: #851cef;
}
.ant-tabs-tab {
  font-size: 16px;
}

.arrow_white {
  color: #fff !important;
  margin-top: 6px;
  margin-left: 12px;
}
.ant-radio-button-wrapper {
  min-height: 40px;
  border-radius: 8px;
}
.ant-radio-button-wrapper span {
  vertical-align: sub;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 0px solid transparent !important;
}
