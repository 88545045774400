p {
  color: white;
  padding: 2px;
}
.mint {
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  position: relative;
}
@media (max-width: 1700px) {
  .mint {
    height: 100%;
  }
}

.video-map-main {
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.video-map-main > video {
  position: relative;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  object-fit: fill;
}
.video-map-main > span {
  display: block;
  cursor: pointer;
}

.video-map-main > span > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.video-map-main > span:nth-child(3):before {
  animation: none;
}

.video-map-main > span:hover {
  transition: 0.2s all ease;
  box-shadow: 5px 5px 15px #3cc6d842;
}
.video-map-main > span::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background: none;
  box-shadow: none;
  animation: animate 4s linear infinite;
}

.video-map-main > span:hover:before {
  background: #00ffcc;
  box-shadow: 0px 0px 30px 5px #3cc6d8;
}

.video-map-main > span::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background: none;
  box-shadow: none;
  animation: animate 4s linear infinite;
  animation-direction: reverse;
}

.video-map-main > span:hover:after {
  background: #00ffcc;
  box-shadow: 0px 0px 30px 5px #3cc6d8;
}

.gif-container {
  position: absolute;
  width: 11.2%;
  height: 20%;
  left: 12.6%;
  top: 10%;
}

@keyframes animate {
  0% {
    width: 3px;
    height: 3px;
    top: 0;
    left: 0;
  }

  12% {
    width: 50%;
    height: 3px;
    top: 0;
    left: 40%;
  }

  25% {
    width: 3px;
    height: 3px;
    top: 0;
    left: 100%;
  }

  37% {
    width: 3px;
    height: 50%;
    top: 50%;
    left: 100%;
  }

  50% {
    height: 3px;
    width: 3px;
    top: 100%;
    left: 100%;
  }

  62% {
    width: 50%;
    top: 100%;
    left: 50%;
  }
  75% {
    width: 3px;
    height: 3px;
    top: 100%;
    left: 0;
  }

  87% {
    height: 50%;
    top: 50%;
    left: 0;
  }

  100% {
    height: 3px;
    width: 3px;
    top: 0;
    left: 0;
  }
}
main {
  position: absolute;
  bottom: 130px;
  width: 200px;
  left: 43%;
}
.mint-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mint-info {
  font-size: 1.3em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
}

.mint-info-hover {
  display: none;
}

.mint-container:hover .mint-info-hover {
  display: block;
}

.mint-button {
  margin-top: 2em;
  font-weight: bolder;
  font-size: 1.1rem;
  padding: 1.2em 6em;
  cursor: pointer;
  background: linear-gradient(180deg, #0080ff 0%, #1cc4c4 100%);
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);

  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
}

.mint-button:hover {
  opacity: 0.9;
}
.video-map-main {
  display: none;
}
.video-map-main:first-of-type {
  display: block;
}

@media (max-width: 900px) {
  .mint {
    height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .video-map-main {
    display: block;
    left: -100%;
    width: 300% !important;
    height: 100vh;
  }
  .video-map-main:first-of-type {
    left: 0;
  }
  .video-map-main:last-of-type {
    left: -200%;
  }
}

.MuiButton-label {
  color: white;
}

.MuiTypography-root {
  color: white !important;
}

.MuiDialogTitle-root {
  color: white !important;
}

.roading-component {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
